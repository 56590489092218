import { useMemo } from 'react';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useEvals } from '@cloud-ui/hooks/useEvals';
import { Typography, Box, Tooltip, Stack } from '@mui/material';
import { type GridRenderCellParams } from '@mui/x-data-grid';
import type { EvalWithAuthor } from '@shared/dto';

/**
 * Displays a list of evals.
 *
 * @param onEvalSelected - Callback to handle when an eval is selected (via clicking on its id cell).
 * @param focusedEvalId - An optional ID of the eval to focus when the grid loads.
 */
export default function EvalsDataGrid({
  onEvalSelected,
  focusedEvalId,
  showUtilityButtons = false,
}: {
  onEvalSelected: (evalId: string) => void;
  focusedEvalId?: string;
  showUtilityButtons?: boolean;
}) {
  const { currentTeam } = useTeamsContext();
  const { data, isLoading } = useEvals({ teamId: currentTeam?.id });

  const hasRedteamEvals = data?.some((_eval) => _eval.isRedteam) ?? false;

  const handleCellClick = (params: any) => onEvalSelected(params.row.evalId);

  const columns: GridColDef<EvalWithAuthor>[] = useMemo(
    () =>
      [
        {
          field: 'evalId',
          headerName: 'ID',
          flex: 1,
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'createdAt',
          headerName: 'Created',
          flex: 1,
          valueFormatter: (value: EvalWithAuthor['createdAt']) => new Date(value).toLocaleString(),
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'author',
          headerName: 'Author',
          flex: 1,
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1,
          valueGetter: (value: EvalWithAuthor['description'], row: EvalWithAuthor) =>
            value ?? row.label,
          cellClassName: 'dg-cursor-pointer',
        },
        hasRedteamEvals
          ? {
              field: 'pluginIds',
              headerName: 'Plugins',
              flex: 1,
              // TODO: Enable sorting and filtering
              sortable: false,
              filterable: false,
              renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => {
                const count = params.value.length;
                const label = (
                  <Typography variant="body2" color={count > 0 ? 'primary' : 'text.secondary'}>
                    {count} plugin
                    {count === 1 ? '' : 's'}
                  </Typography>
                );
                const pluginIds = (params?.value ?? []) as string[];

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      cursor: 'default',
                    }}
                  >
                    {count > 0 ? (
                      <Tooltip
                        title={
                          <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {pluginIds.map((pluginId) => (
                                <Typography key={pluginId} variant="body2">
                                  {pluginId}
                                </Typography>
                              ))}
                            </Stack>
                          </Box>
                        }
                      >
                        {label}
                      </Tooltip>
                    ) : (
                      label
                    )}
                  </div>
                );
              },
              cellClassName: 'dg-cursor-pointer',
            }
          : null,
        hasRedteamEvals
          ? {
              field: 'strategies',
              headerName: 'Strategies',
              flex: 1,
              // TODO: Enable sorting and filtering
              sortable: false,
              filterable: false,
              renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => {
                const count = params.value.length;
                const label = (
                  <Typography variant="body2" color={count > 0 ? 'primary' : 'text.secondary'}>
                    {count} {count === 1 ? 'strategy' : 'strategies'}
                  </Typography>
                );
                const strategies = (params?.value ?? []) as string[];

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      cursor: 'default',
                    }}
                  >
                    {count > 0 ? (
                      <Tooltip
                        title={
                          <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {strategies.map((strategyId) => (
                                <Typography key={strategyId} variant="body2">
                                  {strategyId}
                                </Typography>
                              ))}
                            </Stack>
                          </Box>
                        }
                      >
                        {label}
                      </Tooltip>
                    ) : (
                      label
                    )}
                  </div>
                );
              },
              cellClassName: 'dg-cursor-pointer',
            }
          : null,
        {
          field: 'passRate',
          headerName: 'Pass Rate',
          flex: 1,
          renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <Typography variant="body2" color="success.main">
                {params.value}%
              </Typography>
            </div>
          ),
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'numTests',
          headerName: '# Tests',
          flex: 1,
          cellClassName: 'dg-cursor-pointer',
        },
      ].filter(Boolean) as GridColDef<EvalWithAuthor>[],
    [hasRedteamEvals],
  );

  return (
    <DataGrid<EvalWithAuthor>
      rows={data ?? []}
      columns={columns}
      isLoading={isLoading}
      idColumn="evalId"
      defaultPageSize={50}
      defaultSort={{ field: 'createdAt', sort: 'desc' }}
      noResults={() => (
        <Box
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '2rem', mb: 1 }}>🔍</Box>
          <Typography variant="h6" gutterBottom>
            No evaluations found
          </Typography>
          <Typography variant="body2">
            Try adjusting your search or create a new evaluation
          </Typography>
        </Box>
      )}
      selectedRowId={focusedEvalId}
      showUtilityButtons={showUtilityButtons}
      onCellClick={handleCellClick}
    />
  );
}
