import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchIdentityProviders } from '@cloud-ui/utils/api/fusionauth';
import { Box, CircularProgress } from '@mui/material';
import type { FusionAuthConfig } from '@shared/dto/config';
import { useConfig } from './ConfigContext';

interface LoginUrlBuilder {
  buildLoginUrl: (state?: string) => string;
  buildLogoutUrl: (postLogoutRedirectUri?: string) => string;
  buildRegistrationUrl: (inviteCode?: string | null) => string;
  fusionAuthConfig: FusionAuthConfig;
}

const FusionAuthContext = createContext<LoginUrlBuilder | null>(null);

const IDP_CACHE_KEY = 'fusionauth_idp_hint';

export function useFusionAuth() {
  const context = useContext(FusionAuthContext);
  if (context === undefined) {
    throw new Error('useFusionAuth must be used within a ConfiguredFusionAuth provider');
  }
  return context;
}

export function ConfiguredFusionAuth({ children }: { children: React.ReactNode }) {
  const { config: contextConfig, isLoading: configLoading, error: configError } = useConfig();

  // Initialize with null, we'll load from cache in useEffect
  const [identityProviderId, setIdentityProviderId] = useState<string | null>(null);
  const [isIdpCacheLoading, setIsIdpCacheLoading] = useState(true);

  useEffect(() => {
    const loadCachedIdentityProvider = () => {
      try {
        const cachedIdJSON = localStorage.getItem(IDP_CACHE_KEY);
        if (cachedIdJSON) {
          try {
            const cachedData = JSON.parse(cachedIdJSON);
            if (cachedData && cachedData.id) {
              console.log('Using cached identity provider ID:', cachedData.id);
              setIdentityProviderId(cachedData.id);
            }
          } catch (parseErr) {
            console.error('Error parsing identity provider data:', parseErr);
          }
        }
      } catch (err) {
        console.error('Error reading from localStorage:', err);
      } finally {
        setIsIdpCacheLoading(false);
      }
    };

    loadCachedIdentityProvider();
  }, []);

  const {
    data: idpData,
    isLoading: isIdpLoading,
    isError: isIdpError,
    error: idpError,
  } = useQuery({
    queryKey: ['fusionauth', 'identityProviders'],
    queryFn: fetchIdentityProviders,
    enabled: Boolean(
      contextConfig?.useFusionAuth &&
        contextConfig?.auth?.fusionauth &&
        !identityProviderId &&
        !isIdpCacheLoading, // Only fetch if we've finished checking the cache
    ),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours - cache for a day
  });

  useEffect(() => {
    if (idpData?.identityProviderId && !identityProviderId) {
      console.log('Setting identity provider ID from React Query:', idpData.identityProviderId);

      try {
        localStorage.setItem(
          IDP_CACHE_KEY,
          JSON.stringify({
            id: idpData.identityProviderId,
            timestamp: Date.now(),
          }),
        );
      } catch (err) {
        console.error('Error saving to localStorage:', err);
      }

      setIdentityProviderId(idpData.identityProviderId);
    }
  }, [idpData, identityProviderId]);

  useEffect(() => {
    if (isIdpError && idpError) {
      console.error('Error fetching identity providers:', idpError);
    }
  }, [isIdpError, idpError]);

  const showLoading = configLoading || isIdpLoading || isIdpCacheLoading;

  if (showLoading) {
    console.log('FusionAuth config or IdP is loading...');
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (configError || (isIdpError && idpError)) {
    console.error('Failed to load FusionAuth configuration', configError || idpError);
    return <div>Failed to load authentication configuration. Please refresh the page.</div>;
  }

  // If FusionAuth is not used, use a dummy provider
  if (!contextConfig?.useFusionAuth) {
    console.log('FusionAuth is not enabled, using dummy provider');
    const dummyProvider = {
      buildLoginUrl: () => '',
      buildLogoutUrl: () => '',
      buildRegistrationUrl: () => '',
      fusionAuthConfig: {
        apiUrl: '',
        appUrl: '',
        applicationId: '',
        redirectUri: '',
        scope: '',
        useFusionAuth: false,
      },
    };

    return (
      <FusionAuthContext.Provider value={dummyProvider}>{children}</FusionAuthContext.Provider>
    );
  }

  // If missing FusionAuth config, show error
  if (!contextConfig?.auth?.fusionauth) {
    console.error('FusionAuth configuration is missing in the config');
    return <div>FusionAuth configuration is incomplete. Please check server settings.</div>;
  }

  const fusionauth = contextConfig.auth.fusionauth;

  const buildLoginUrl = (state?: string) => {
    const params = new URLSearchParams({
      client_id: fusionauth.applicationId,
      response_type: 'code',
      redirect_uri: fusionauth.redirectUri,
    });

    if (fusionauth.scope) {
      params.append('scope', fusionauth.scope);
    }

    if (state) {
      params.append('state', state);
    }
    if (identityProviderId) {
      params.append('idp_hint', identityProviderId);
    }

    const url = `${fusionauth.appUrl}/oauth2/authorize?${params.toString()}`;

    return url;
  };

  const buildLogoutUrl = () => {
    const baseUrl = `${fusionauth.appUrl}/oauth2/logout`;

    const params = new URLSearchParams({
      client_id: fusionauth.applicationId,
    });
    const redirectUrl = `${window.location.origin}/logout`;
    params.append('post_logout_redirect_uri', redirectUrl);
    if (identityProviderId) {
      params.append('idp_hint', identityProviderId);
    }

    return `${baseUrl}?${params.toString()}`;
  };

  const buildRegistrationUrl = (invite_code?: string | null) => {
    const baseUrl = `${fusionauth.appUrl}/oauth2/register`;

    const params = new URLSearchParams({
      client_id: fusionauth.applicationId,
      response_type: 'code',
      redirect_uri: `${window.location.origin}/auth/fusionauth/callback`,
      scope: fusionauth.scope,
    });

    if (invite_code) {
      params.append('invite_code', invite_code);
    }

    return `${baseUrl}?${params.toString()}`;
  };

  return (
    <FusionAuthContext.Provider
      value={{
        buildLoginUrl,
        buildLogoutUrl,
        buildRegistrationUrl,
        fusionAuthConfig: fusionauth,
      }}
    >
      {children}
    </FusionAuthContext.Provider>
  );
}
