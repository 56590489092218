import { useState } from 'react';
import { Box } from '@mui/material';
import {
  DataGrid as BaseDataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  type GridColDef,
  type GridRowSelectionModel,
  type GridEventListener,
} from '@mui/x-data-grid';
import './DataGrid.css';

type PageSize = 5 | 10 | 25 | 50 | 100;

type Sort = { field: string; sort: 'asc' | 'desc' };

type Props<T> = {
  columns: GridColDef[];
  rows: T[];
  isLoading?: boolean;
  defaultPageSize?: PageSize;
  defaultSort?: Sort;
  idColumn?: string;
  noResults?: () => React.ReactNode;
  selectedRowId?: string;
  showUtilityButtons?: boolean;
  onCellClick?: GridEventListener<'cellClick'>;
};

// augment the props for the toolbar slot
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    showUtilityButtons: boolean;
  }
}

function CustomToolbar({ showUtilityButtons }: { showUtilityButtons: boolean }) {
  return (
    <GridToolbarContainer>
      {showUtilityButtons && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

/**
 * Provides a wrapper around the MUI DataGrid component that adds some suitable defaults.
 *
 * @param columns - The columns to display. For example:
 * ```ts
 * const columns: GridColDef<{ name: string; age: number }>[] = [
 *   { field: 'name', headerName: 'Name' },
 *   { field: 'age', headerName: 'Age' },
 * ];
 * ```
 *
 * @param rows - The rows to display.
 * @param isLoading - Whether the data is loading.
 * @param defaultPageSize - The default page size.
 * @param defaultSort - The default sort.
 * @param idColumn - Each row must contain a UUID defined by this column.
 * @param noResults - The component to display when there are no results.
 * @param selectedRowId - An optional id of the row to select (focus on) when the grid loads.
 * @param showUtilityButtons - Whether to show the utility buttons in the toolbar.
 */
export default function DataGrid<T>({
  columns,
  rows,
  isLoading = false,
  defaultPageSize = 25,
  defaultSort,
  idColumn = 'id',
  noResults,
  selectedRowId,
  showUtilityButtons = false,
  onCellClick = () => {},
}: Props<T>) {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(
    selectedRowId ? [selectedRowId] : [],
  );

  return (
    <BaseDataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      initialState={{
        pagination: {
          paginationModel: { pageSize: defaultPageSize, page: 0 },
        },
        sorting: {
          sortModel: defaultSort ? [defaultSort] : undefined,
        },
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      loading={isLoading}
      getRowId={(row) => row[idColumn]}
      slots={{
        toolbar: CustomToolbar,
        noRowsOverlay: noResults ?? undefined,
      }}
      slotProps={{ toolbar: { showUtilityButtons } }}
      onRowSelectionModelChange={setRowSelectionModel}
      rowSelectionModel={rowSelectionModel}
      onCellClick={onCellClick}
    />
  );
}

// Enable importing supplementary types from this module:
export { type GridColDef };
