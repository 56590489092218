import { useSearchParams } from 'react-router-dom';
import { useFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import { Box, Button, Container, Paper, Typography, useTheme } from '@mui/material';

export default function Register() {
  const [searchParams] = useSearchParams();
  const searchEvalId = searchParams.get('eval_id');
  const inviteCode = searchParams.get('invite_code');
  const fusionAuth = useFusionAuth();
  const theme = useTheme();

  if (searchEvalId) {
    localStorage.setItem('redirect_to_eval_on_login', searchEvalId);
  }

  if (!inviteCode) {
    window.location.href = fusionAuth!.buildRegistrationUrl(inviteCode);
  }
  console.log(fusionAuth!.buildRegistrationUrl(inviteCode));

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
        >
          Someone shared an invite link with you
        </Typography>

        <Box sx={{ width: '100%' }}>
          <Button
            sx={{ mb: 2 }}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => (window.location.href = fusionAuth!.buildRegistrationUrl(inviteCode))}
          >
            Click here to register
          </Button>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => (window.location.href = fusionAuth!.buildLoginUrl())}
          >
            Already have an account? Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
