import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { callApi, getAuthToken, setAuthToken } from '@cloud-ui/utils/api';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import type { OrganizationDTO } from '@shared/dto/organizations';
import posthog from 'posthog-js';

interface User {
  id: string;
  name: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  organization: OrganizationDTO | null;
  login: (token: string) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  isAdmin: boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<OrganizationDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showTokenPopup, setShowTokenPopup] = useState(false);
  const [token, setToken] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const fetchUserData = async () => {
    try {
      if (!getAuthToken()) {
        return;
      }
      const response = await callApi('/users/me');
      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        setOrganization(data.organization);
        posthog.identify(data.user.id, {
          organizationId: data.organization.id,
          organization: data.organization.name,
        });
        setIsAdmin(data.isAdmin);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (token: string) => {
    setAuthToken(token);
    await fetchUserData();
  };

  const logout = () => {
    // Clear auth state
    setAuthToken('');
    setUser(null);
    setOrganization(null);

    // Clear tokens from storage
    localStorage.removeItem('fusionauth_tokens');
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    const fromCLI = new URLSearchParams(window.location.search).get('fromCLI');
    if (token) {
      login(token);

      if (fromCLI === 'true') {
        setToken(token);
        setShowTokenPopup(true);
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      fetchUserData();
    }
  }, []);

  const copyTokenToClipboard = () => {
    navigator.clipboard.writeText(token);
    setSnackbarOpen(true);
  };

  const closeTokenPopup = () => {
    setShowTokenPopup(false);
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <AuthContext.Provider value={{ isAdmin, user, organization, login, logout, isLoading }}>
      {children}
      <Dialog open={showTokenPopup} onClose={closeTokenPopup} maxWidth="sm" fullWidth>
        <DialogTitle>
          Your Authentication Token
          <IconButton
            aria-label="close"
            onClick={closeTokenPopup}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Use this token to authenticate your Promptfoo CLI.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={token}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={copyTokenToClipboard} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTokenPopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Token copied to clipboard"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isLoading, organization } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div></div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!organization) {
    return <Navigate to="/organization/new" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export const RequireRedteamAccess = ({ children }: { children: React.ReactNode }) => {
  const { organization, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div></div>;
  }

  if (!organization || !organization.canUseRedteam) {
    return <Navigate to="/upgrade" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
